<template>
  <div v-if="!bindVisible">
    <div class="card" style='display: flex;'>
      <div class="left">
        <el-button type='primary'  icon='el-icon-plus' @click="bindVisible = true">RFID绑定</el-button>
      </div>
      <div class='line'></div>
      <div class="middle">
        <el-form :model="form" :inline="true">
          <el-form-item label="货号" v-model="form.goods_number">
            <el-input placeholder="请输入货号" v-model="form.goods_number" clearable @keydown.enter.native="onSubmit"></el-input>
          </el-form-item>
          <el-form-item label="品类">
            <SelectTree
                :props="props"
                :options="treeData"
                :clearable="isClearable"
                :accordion="isAccordion"
                @getValue="getValue($event)"
              />
          </el-form-item>
          <el-form-item label="材质">
            <el-select v-model="form.material_name" clearable>
              <template v-for="item in materialList">
                <el-option :label="item.attr_value" :key="item.attr_value_id" :value="item.attr_value"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        height="700"
        stripe
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width">
            <template slot-scope="scope">
              <template v-if="item.field_alias === 'goods_name'">
                <div style="display: flex; align-items: center;">
                  {{scope.row.goods_name}}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-left: 5px; height: 15px;" />
                  </template>
                </div>
              </template>
              <template v-else>
                {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" style="color: #F56C6C;" @click="cancleBind(scope.row)">解绑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total">
      </el-pagination>
    </div>
  </div>
  <Bind v-else @onHide="handleHide" />
</template>

<script>
import {
  getListReq,
} from '@/api/goods/goodsConfig/classify';
import { getRfidListReq, getMaterialListReq, bindReq } from '@/api/goods/RFID/list';
import Bind from '../bind/Index.vue';

export default {
  data() {
    return {
      materialList: [],
      bindVisible: false,
      loading: false,
      page: 1,
      limit: this.$store.state.pageSizes[0],
      form: {},
      treeData: [],
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: 'RFID', field_alias: 'rfid_number' },
        { field_text: '货号', field_alias: 'goods_number' },
        { field_text: '品类', field_alias: 'goods_type_name' },
        { field_text: '品名', field_alias: 'goods_name' },
        { field_text: '材质', field_alias: 'material_name' },
        { field_text: '款式', field_alias: 'jewe_name' },
        { field_text: '标签价', field_alias: 'sale_price' },
      ],
      tableData: {
        list: [],
        total: 0,
      },
    };
  },
  components: {
    Bind,
  },
  created() {
    this.getGoodsType();
    this.getMaterialList();
    this.getRfidList();
  },
  methods: {
    resetForm() {
      this.form = {};
      this.page = 1;
      this.getRfidList();
    },
    // 解绑
    cancleBind(row) {
      this.$confirm('确定要解绑该RFID吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const bindData = [{
          goods_number: row.goods_number,
          rfid_number: row.rfid_number,
        }];
        bindReq({ bindData, bindType: 2 })
          .then((res) => {
            if (res.code === 1) {
              this.$message.success('解绑成功');
              this.getRfidList();
            }
          });
      });
    },
    handleHide(getList) {
      if (getList) {
        this.getRfidList();
      }
      this.bindVisible = false;
    },
    // 分页页数改变
    handleCurrentChange(page) {
      this.page = page;
      this.getRfidList();
    },
    // 分页条数改变
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getRfidList();
    },
    // 获取材质成色列表
    getMaterialList() {
      getMaterialListReq()
        .then((res) => {
          if (res.code === 1) {
            this.materialList = res.data;
          }
        });
    },
    // 获取RFID列表
    getRfidList() {
      this.loading = true;
      getRfidListReq({ ...this.form, page: this.page, limit: this.limit })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取品类
    getGoodsType() {
      getListReq()
        .then((res) => {
          if (res.code === 1) {
            this.treeData = res.data;
          }
        });
    },
    onSubmit() {
      this.getRfidList();
    },
    // 取值
    getValue(value) {
      const form = { ...this.form };
      form.goods_type_id = value;
      this.form = form;
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
