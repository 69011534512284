import { post } from '@/utils/request';

// 获取rfid列表
const getRfidListReq = (params) => post({
  url: '/store/goods.Goods/getRfidList',
  data: {
    ...params,
  },
});

// 获取材质成色列表
const getMaterialListReq = () => post({
  url: '/store/common.Select/getMaterialList',
});

// 是否能判断rfid
const bindGoodsRFIDReq = (params) => post({
  url: '/store/goods.Goods/bindGoodsRFID',
  data: {
    ...params,
  },
});

// 绑定rfid
const bindReq = (params) => post({
  url: '/store/goods.Goods/bindGoodsRFIDDo',
  data: {
    ...params,
  },
});

export {
  getRfidListReq,
  getMaterialListReq,
  bindGoodsRFIDReq,
  bindReq,
};
