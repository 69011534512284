<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="绑定RFID">
      </el-page-header>
    </div>
    <div class="wrapper">
      <div style="font-weight: bold; text-indent: 20px;">使用帮助</div>
      <el-divider></el-divider>
      <div style="padding: 0 20px;">
        添加货号再添加RFID，确认无误后点击绑定<br />

        1. 鼠标点击货号输入框成选中状态，输入货号点击回车<br />

        2. 扫码枪扫码货品编码<br />

        3. 放入RFID标至RFID录入设备上<br />

        4. 点击左下角绑定按钮即可成功绑定RFID<br />

        注释：如果绑定过程中出现绑定错误，可删除右边表格内对应货品重新绑定。<br />
      </div>
    </div>
    <div class="form">
      <el-form :model="form" label-width="100px">
        <el-form-item label="货号" required>
          <el-input placeholder="扫码或输入货号" v-model="form.goods_number" style="width: 40%;" id="numberInput" @keyup.enter.native="handleEnterByNumber" clearable></el-input>
        </el-form-item>
        <el-form-item label="RFID" required>
          <el-input placeholder="读卡器输入RFID码" @input="handleInput" v-model="form.rfid_number"  style="width: 40%;" id="rfidInput" clearable  @keyup.enter.native="handleEnterByRFID"></el-input>
          <el-button type="primary" style="margin-left: 20px;" @click="handleEnterByRFID">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        height="250"
        :row-class-name="handleIndex"
        stripe
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width">
            <template slot-scope="scope">
              {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" style="color: #F56C6C;" @click="handleDele(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="background: white; padding: 10px; display: flex; justify-content: space-between;">
      <el-button type="primary" @click="handleBind" :loading="bindLoading">保存</el-button>
      <span>合计 {{tableData.list.length}} 件</span>
    </div>
  </div>
</template>

<script>
import { bindGoodsRFIDReq, bindReq } from '@/api/goods/RFID/list';
import goodsReq from '@/components/goods/chooseGoods/index';

export default {
  data() {
    return {
      stock: {}, // 可以绑的数量
      bindLoading: false,
      onlyRFID: {},
      loading: false,
      form: {
        goods_number: '',
        rfid_number: '',
      },
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: 'RFID', field_alias: 'rfid_number' },
        { field_text: '货号', field_alias: 'goods_number' },
        { field_text: '品类', field_alias: 'goods_type_name' },
        { field_text: '材质', field_alias: '100' },
        { field_text: '款式', field_alias: '101' },
        { field_text: '标签价', field_alias: 'sale_price' },
      ],
      tableData: {
        list: [],
        total: 0,
      },
      timer: null, // 节流
    };
  },
  mounted() {
    this.handleNumberInputFocus();
  },
  methods: {
    handleIndex({row, rowIndex}) {
      row.index = rowIndex + 1;
    },
    goBack() {
      this.$emit('onHide');
    },

    // 保存
    handleBind() {
      if (!this.tableData.list.length) {
        this.$message.error('请绑定RFID');
        return;
      }
      const bindData = [];
      this.tableData.list.forEach((item) => {
        const obj = {
          goods_number: item.goods_number,
          rfid_number: item.rfid_number,
        };
        bindData.push(obj);
      });
      const params = {
        bindData,
      };
      this.bindLoading = true;
      bindReq({ ...params, bindType: 1 })
        .then((res) => {
          if (res.code === 1) {
            this.bindLoading = false;
            this.$message.success('绑定成功');
            this.$emit('onHide', 'getList');
          }
        })
        .catch(() => {
          this.bindLoading = false;
        });
    },

    // 删除
    handleDele(row) {
      this.tableData.list.splice(row.index - 1, 1);
      this.onlyRFID[row.rfid_number] = false;
      this.stock[row.goods_number] -= 1;
    },

    // 监听输入
    handleInput(e) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setTimeout(() => {
        const pattern = new RegExp('[\u4E00-\u9FA5]+');
        if (pattern.test(this.form.rfid_number)) {
          this.$message.error('RFID不能存在中文');
        }
      }, 700);
    },

    // 回车事件
    handleEnterByNumber() {
      if (!this.form.goods_number) {
        this.$message.error('请输入货号');
        return;
      }
      this.bindGoodsRFID(() => {
        this.handleRFIDInputFocus();
      });
    },

    // rfid回车事件
    handleEnterByRFID() {
      if (!this.form.rfid_number) {
        this.$message.error('请输入RFID');
        return;
      }
      // 如果rfid有中文
      const pattern = new RegExp('[\u4E00-\u9FA5]+');
      if (pattern.test(this.form.rfid_number)) {
        this.$message.error('RFID不能存在中文');
        return;
      }
      if (this.onlyRFID[this.form.rfid_number]) {
        this.$message.error('RFID已存在');
        return;
      }
      this.bindGoodsRFID(() => {
        // 请求获得详情
        goodsReq({ goods_number: this.form.goods_number })
          .then((res) => {
            if (res.code === 1) {
              const item = res.data.list[0];
              const obj = {
                rfid_number: this.form.rfid_number,
                goods_number: this.form.goods_number,
                goods_type_name: item.goods_type_name,
                100: item['100'],
                101: item['101'],
                sale_price: item.sale_price,
              };
              // RFID是唯一的 需要进行去重;
              // 标识已存在的RFID;
              this.onlyRFID[this.form.rfid_number] = true;
              this.tableData.list.push(obj);
              // 清空数据
              const form = {
                goods_number: '',
                rfid_number: '',
              };
              this.form = form;
              this.handleNumberInputFocus();
            }
          });
      });
    },

    // 判断是否可以绑定rfid
    bindGoodsRFID(callback) {
      bindGoodsRFIDReq({
        ...this.form,
      })
        .then((res) => {
          if (res.code === 1) {
            if (!res.data.stock) {
              this.$message.error(res.data.msg || '该货号已绑定完成。');
              return;
            }
            if (this.tableData.list.length) {
              let m = 0;
              for (let i = 0; i < this.tableData.list.length; i += 1) {
                const item = this.tableData.list[i];
                if (item.goods_number === this.form.goods_number) {
                  m += 1;
                }
                if (m === this.stock[this.form.goods_number]) {
                  this.$message.error('该货号已到最大绑定数量。');
                  return;
                }
              }
            }
            this.stock[this.form.goods_number] = res.data.stock;
            // 该货号存在且未绑定，光标跳到下一个
            if (callback && (typeof callback === 'function')) {
              callback();
            }
          }
        });
    },
    // 货号input聚焦
    handleNumberInputFocus() {
      const numberInputDom = document.getElementById('numberInput');
      numberInputDom.focus();
    },
    // RFIDinput聚焦
    handleRFIDInputFocus() {
      const rfidInputDom = document.getElementById('rfidInput');
      rfidInputDom.focus();
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  background: white;
  padding: 20px;
  line-height: 30px;
}
.form {
  background: white;
  margin-top: 10px;
  padding: 20px 0px;
}
</style>
